import type { Product, Variant } from 'types/product';

import type { ProductQueries } from '@/utils/product/types';

/**
 * Select the current variant and product images based on given filter.
 * Replace the first image with the variant image if it exists.
 */
export const getCurrentVariantAndProductImages = (
    product: Product,
    variants: Variant[],
    filter: ProductQueries | undefined,
) => {
    const productImages =
        product.images ??
        (product.ImageURL
            ? [
                  {
                      src: product.ImageURL,
                      type: 'image',
                      id: product.id,
                      alt: product.title,
                  },
              ]
            : []);

    if (!filter?.variant)
        return {
            productImages,
            currentVariant: variants.length === 1 ? variants[0] : undefined,
        };
    const currentVariant =
        variants.find((variant) => variant.id === filter.variant) ??
        variants[0];
    return {
        productImages: [
            currentVariant?.image ?? productImages[0],
            ...productImages.slice(1),
        ],
        currentVariant,
    };
};
