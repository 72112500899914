import { service } from '@server/client';
import type {
    UpdateCheckoutPayerMutation,
    UpdateCheckoutPayerMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutPayerMutation } from '@server/queries/checkout';

export const updateCheckoutPayer = async (
    args: UpdateCheckoutPayerMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutPayerMutation,
            UpdateCheckoutPayerMutationVariables
        >({
            query: updateCheckoutPayerMutation,
            variables: args,
        });
        return response.checkout;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateCheckoutPayer)'),
        );
        return e?.response?.data
            ?.checkout as UpdateCheckoutPayerMutation['checkout'];
    }
};
