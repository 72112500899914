import { service } from '@server/client';
import type {
    UpdateCheckoutReceiverMutation,
    UpdateCheckoutReceiverMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutReceiverMutation } from '@server/queries/checkout';

export const updateCheckoutReceiver = async (
    args: UpdateCheckoutReceiverMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutReceiverMutation,
            UpdateCheckoutReceiverMutationVariables
        >({
            query: updateCheckoutReceiverMutation,
            variables: args,
        });
        return response.checkout;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateCheckoutReceiver)'),
        );
        return e?.response?.data
            ?.checkout as UpdateCheckoutReceiverMutation['checkout'];
    }
};
