import { graphql } from '@server/gql';

export const setDeliveryOptionMutation = graphql(/* GraphQL */ `
    mutation setDeliveryOption(
        $locale: String!
        $cartId: String!
        $zipCode: String!
        $deliveryOptionId: String!
        $deliveryAgent: String!
        $priceValue: Float!
        $deliveryOptionName: String!
    ) {
        setDeliveryOption(
            locale: $locale
            cartId: $cartId
            zipCode: $zipCode
            deliveryOptionId: $deliveryOptionId
            deliveryAgentId: $deliveryAgent
            deliveryOptionName: $deliveryOptionName
            priceValue: $priceValue
        ) {
            id
        }
    }
`);
