import { service } from '@server/client';
import type {
    UpdateCheckoutMethodMutation,
    UpdateCheckoutMethodMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutMethodMutation } from '@server/queries/checkout';

export const updateCheckoutMethod = async (
    variables: UpdateCheckoutMethodMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutMethodMutation,
            UpdateCheckoutMethodMutationVariables
        >({
            query: updateCheckoutMethodMutation,
            variables,
        });

        return response.checkout;
    } catch (error) {
        console.error('error in updateCheckoutMethod:', error);
        throw error;
        // console.group(
        //     ...(e.response?.errors ?? []).map((err: any) => console.debug(err)),
        //     console.debug('(in updateCheckoutMethod)'),
        // );
        // return e?.response?.data
        //     ?.checkout as UpdateCheckoutMethodMutation['checkout'];
    }
};
