import { service } from '@server/client';
import type {
    GetCheckoutFormQuery,
    GetCheckoutFormQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutFormQuery } from '@server/queries/checkout';

export const getCheckoutForm = async (args: GetCheckoutFormQueryVariables) => {
    try {
        const response = await service<
            GetCheckoutFormQuery,
            GetCheckoutFormQueryVariables
        >({
            query: getCheckoutFormQuery,
            variables: args,
        });

        return response.checkoutForm;
    } catch (e: any) {
        console.group(
            ...(e?.response?.errors ?? []).map((err: any) =>
                console.debug(err),
            ),
            console.debug('(in getCheckoutForm)'),
        );
        return e?.response?.data
            ?.checkoutForm as GetCheckoutFormQuery['checkoutForm'];
    }
};
