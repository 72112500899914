import getPageNumberFromLocation from '@server/utils/getPageNumberFromLocation';
import type {
    Filter,
    // FilterRangeAttribute,
    Loop54Content,
} from '@server/utils/parseLoop/types';
import type { Sort } from 'types';

// import { RANGE_FILTER_ATTRIBUTES } from '../const';

export type Params = {
    page: number;
    filters: Filter[];
    sort?: Sort;
};

export const hashPartsReducer = (a: Params, b: string): Params => {
    const [key, value] = b.split('=');

    if (key === 'sort')
        return {
            ...a,
            sort: value as Sort,
        };

    if (!Number.isNaN(parseInt(value, 10)) && value.includes('-')) {
        const index = a.filters.findIndex((f) => f.attribute === key);

        if (index < 0) return a;

        const filter = a.filters[index];

        if (filter.type === 'range') {
            const [min, max] = value.split('-');

            a.filters.splice(index, 1, {
                ...filter,
                type: 'range',
                values: [Number(min), Number(max)] as any,
            });
        }
    } else {
        const index = a.filters.findIndex((f) => f.attribute === key);

        if (index < 0) return a;

        const filter = a.filters[index];

        if (filter.type === 'checkbox') {
            a.filters.splice(index, 1, {
                ...filter,
                items: [],
                type: 'checkbox',
                values: value.split(','),
            });
        }
    }

    return a;
};

export const getInitialParams = (initialData?: Loop54Content): Params => {
    if (typeof window === 'undefined')
        return {
            page: 0,
            filters: [],
        };

    const hash = window.location.hash.toString().replace('#', '');
    const escapedHash = decodeURI(hash);
    const parts = escapedHash.split(';').filter(Boolean);
    const pageNumber = getPageNumberFromLocation(window.location.pathname);
    const initialValue: Params = {
        filters: [...(initialData?.filter ?? [])] ?? [],
        page: pageNumber,
    };

    return parts.reduce(hashPartsReducer, initialValue);
};
