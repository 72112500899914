import { service } from '@server/client';
import type {
    GetCheckoutQuery,
    GetCheckoutQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutQuery } from '@server/queries/checkout';

export const getCheckout = async (args: GetCheckoutQueryVariables) => {
    try {
        const response = await service<
            GetCheckoutQuery,
            GetCheckoutQueryVariables
        >({
            query: getCheckoutQuery,
            variables: args,
        });
        return response.checkout;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in getCheckout)'),
        );
        return e?.response?.data?.checkout as GetCheckoutQuery['checkout'];
    }
};
