import { service } from '@server/client';
import type {
    GetCheckoutDeliveryQuery,
    GetCheckoutDeliveryQueryVariables,
} from '@server/gql/graphql';
import { getCheckoutDeliveryQuery } from '@server/queries/checkout';

export const getCheckoutDelivery = async (
    args: GetCheckoutDeliveryQueryVariables,
) => {
    try {
        const response = await service<
            GetCheckoutDeliveryQuery,
            GetCheckoutDeliveryQueryVariables
        >({
            query: getCheckoutDeliveryQuery,
            variables: args,
        });
        return response.deliveries;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
