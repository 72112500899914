import { useMarket } from '@context/marketContext';
import { fetchAutocomplete } from '@server/requests/product';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useSearchEngineUser from '@/utils/hooks/useSearchEngineUser';

const useAutocomplete = ({ query }: { query: string; enabled?: boolean }) => {
    const { market } = useMarket().state;
    const searchEngineUser = useSearchEngineUser();

    const response = useQuery({
        queryKey: ['autocomplete', query],
        queryFn: () =>
            fetchAutocomplete({
                query,
                market: market.code,
                searchEngineUser,
            }),
        placeholderData: keepPreviousData,
        enabled: !!query,
    });

    return response;
};

export default useAutocomplete;
