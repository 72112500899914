import { service } from '@server/client';
import type {
    ConfirmPaymentSuccessfulQuery,
    ConfirmPaymentSuccessfulQueryVariables,
} from '@server/gql/graphql';
import { confirmPaymentSuccessfulQuery } from '@server/queries/checkout';

export const sendCheckoutCallback = async (
    args: ConfirmPaymentSuccessfulQueryVariables,
) => {
    try {
        const response = await service<
            ConfirmPaymentSuccessfulQuery,
            ConfirmPaymentSuccessfulQueryVariables
        >({
            query: confirmPaymentSuccessfulQuery,
            variables: args,
        });

        if (!response)
            throw new Error('error in sendCheckoutCallback: invalid response');

        return response;
    } catch (error) {
        console.error('error in sendCheckoutCallback:', error);
        throw error;
    }
};
