import { service } from '@server/client';
import type {
    UpdateCheckoutBuyerMutation,
    UpdateCheckoutBuyerMutationVariables,
} from '@server/gql/graphql';
import { updateCheckoutBuyerMutation } from '@server/queries/checkout';

export const updateCheckoutBuyer = async (
    args: UpdateCheckoutBuyerMutationVariables,
) => {
    try {
        const response = await service<
            UpdateCheckoutBuyerMutation,
            UpdateCheckoutBuyerMutationVariables
        >({
            query: updateCheckoutBuyerMutation,
            variables: args,
        });
        return response.checkout;
    } catch (e: any) {
        console.group(
            ...e.response.errors.map((err: any) => console.debug(err)),
            console.debug('(in updateCheckoutBuyer)'),
        );
        return e?.response?.data
            ?.checkout as UpdateCheckoutBuyerMutation['checkout'];
    }
};
